import { useRef } from 'react'

import {
  Flex,
  Box,
  useDisclosure,
  Drawer,
  useBreakpointValue,
  Spacer,
} from '@chakra-ui/react'
import { faBars } from '@fortawesome/pro-regular-svg-icons'
import { ItList } from 'types/graphql'

import Icon from 'src/components/widgets/Icon/Icon'
import Logo from 'src/components/widgets/Logo/Logo'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'

import HeaderActions from '../HeaderActions/HeaderActions'

import DrawerLoggedIn from './DrawerLoggedIn/DrawerLoggedIn'
import DrawerLoggedOut from './DrawerLoggedOut/DrawerLoggedOut'

const Small: React.FC<{ headerButtonVariant?: string; itList?: ItList }> = ({
  headerButtonVariant,
  itList,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()
  const size = useBreakpointValue({ sm: 'full', md: 'lg' })
  const { isAuthenticated } = useCurrentUser()
  if (size === undefined) return null

  return (
    <Flex as="nav" w="100%" alignItems="center" h="44px">
      <Box
        pr={5}
        fontSize="20px"
        onClick={onOpen}
        ref={btnRef}
        data-testid="open-menu-icon-header"
      >
        <Icon icon={faBars} />
      </Box>
      <Logo width="60px" />
      <Spacer />
      <HeaderActions
        section="mobile-nav"
        label="Make An ItList"
        headerButtonVariant={headerButtonVariant}
        itList={itList}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        size={size}
        finalFocusRef={btnRef}
      >
        {isAuthenticated ? (
          <DrawerLoggedIn onClose={onClose} />
        ) : (
          <DrawerLoggedOut onClose={onClose} />
        )}
      </Drawer>
    </Flex>
  )
}

export default Small
