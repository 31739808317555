import { useEffect } from 'react'

import { useRecoilState } from 'recoil'
import {
  CreatorStatus,
  NetworkType,
  StripeOnboardingStatus,
} from 'types/graphql'

import { uiSettingsState } from 'src/atoms/creator.atom'
import { useAuth } from 'src/auth'
import { getFullName } from 'src/lib/utils'
import {
  BrandColor,
  BrandColorBrightness,
  BrandColorHue,
  UISettings,
} from 'src/types'

type AccountType = 'explorer' | 'creator' | 'admin' | 'anonymous' | 'affiliate'

export type ItListCurrentUser = {
  accountType?: AccountType
  bio?: string
  brandColor?: BrandColor
  brandColorBrightness?: BrandColorBrightness
  brandHue?: BrandColorHue
  brandLogo?: string
  countryCode?: string
  creatorStatus?: CreatorStatus
  email: string
  firstName?: string
  fullName?: string
  id: number
  isAdmin?: boolean
  isAffiliate?: boolean
  isAuthenticated: boolean
  isExplorer?: boolean
  isCreator?: boolean
  lastName?: string
  mixpanelUrl?: string
  networkType?: NetworkType
  networkHandle?: string
  niche?: string
  nicheId?: number
  profileImage?: string
  roles?: string[]
  stripeOnboardingStatus?: StripeOnboardingStatus
  subscription?: string
  uiSettings?: UISettings
  usePassword?: boolean
  username?: string
  webhookUrl?: string
}

export const useCurrentUser = (): ItListCurrentUser => {
  const { currentUser, isAuthenticated: authIsAuthenticated } = useAuth()
  const [uiSettings, setUiSettings] = useRecoilState(uiSettingsState)
  useEffect(() => {
    if (!uiSettings && currentUser?.uiSettings) {
      // We want to avoid having to call reauthenticate after updating
      // the uiSettings, so we store it in the state
      setUiSettings(currentUser.uiSettings as UISettings)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (currentUser) {
    return {
      ...currentUser,
      isAuthenticated: !!authIsAuthenticated && !!currentUser.id,
      fullName: getFullName(currentUser),
      isAdmin: currentUser.roles?.includes('admin'),
      isExplorer: currentUser.roles?.includes('explorer'),
      isCreator: currentUser.roles?.includes('creator'),
      isAffiliate: currentUser.roles?.includes('affiliate'),
      accountType: getAccountType(currentUser.roles),
      uiSettings: uiSettings || (currentUser.uiSettings as UISettings),
      brandHue: currentUser.brandHue as BrandColorHue,
      brandColorBrightness:
        currentUser.brandColorBrightness as BrandColorBrightness,
      brandColor: getBrandColor(
        currentUser.brandHue,
        currentUser.brandColorBrightness
      ),
    }
  } else {
    return {
      id: undefined,
      email: undefined,
      isAuthenticated: false,
      accountType: 'anonymous',
    }
  }
}

function getAccountType(roles: string[]): AccountType {
  if (roles?.includes('admin')) {
    return 'admin'
  } else if (roles?.includes('creator')) {
    return 'creator'
  } else if (roles?.includes('affiliate')) {
    return 'affiliate'
  } else if (roles?.includes('explorer')) {
    return 'explorer'
  } else {
    return 'anonymous'
  }
}

function getBrandColor(
  brandHue: string,
  brandColorBrightness: string
): BrandColor | null {
  if (!brandColorBrightness || !brandHue) {
    return null
  }
  return `${brandHue}.${brandColorBrightness}` as BrandColor
}
