import { Box, SystemProps } from '@chakra-ui/react'

import { Link } from '@redwoodjs/router'

import ItListLogoColor from 'src/assets/image/ItlistLogoColorSVG'
import ItListLogo from 'src/assets/image/ItlistLogoWordmarkSVG'
import { useCurrentUser } from 'src/lib/hooks/auth/useCurrentUser'

type LogoProps = {
  src?: string
  to?: string
} & SystemProps

const Logo = ({ to, src, ...props }: LogoProps) => {
  const { isAuthenticated } = useCurrentUser()

  const Logo = () => {
    if (!isAuthenticated) {
      return <ItListLogoColor width="75px" height="41px" />
    }
    if (!src) {
      return <ItListLogo />
    }
  }

  return to ? (
    <Box {...props} data-testid="logo">
      <Link to={to}>
        <Logo />
      </Link>
    </Box>
  ) : (
    <Box {...props} data-testid="logo">
      <Logo />
    </Box>
  )
}

export default Logo
